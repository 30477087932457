<template>
  <BaseItemForm v-slot="{ cancel }" :item="item" :category="category">
    <b-row align-v="center">
      <b-col>
        <b-row>
          <b-col>
            <BaseInput v-model="item.name" label="Nom du regroupement" />
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <BaseInput rules="required">
              <BaseRadio v-model="item.required" :name="true">
                Cet article est obligatoire
              </BaseRadio>
              <BaseRadio v-model="item.required" :name="false">
                Cet article est facultatif
              </BaseRadio>
            </BaseInput>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <BaseInput
              v-model="item.suggestedQuantity"
              type="number"
              label="Quantité que l'étudiant doit se procurer"
              rules="required"
            />
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <p class="form-control-label mb-1">Choix offerts aux parents</p>
            <BaseButton
              v-if="!showProductSearch"
              type="default"
              icon
              size="sm"
              @click.prevent="showProductSearch = true"
            >
              <span class="btn-inner--icon"
                ><i class="fas fa-plus-circle"
              /></span>
              <span class="btn-inner--text">Ajouter</span>
            </BaseButton>
            <ProductAutocomplete
              v-else
              input-label=""
              :show-tags-list="false"
              placeholder="Nom de produit, un SKU, un ISBN, un code Apple, un auteur, une marque"
              @change="addProductToGroup($event)"
            />
          </b-col>
        </b-row>

        <b-row
          v-for="(product, index) in item.products"
          :key="index"
          align-v="center"
          class="mt-4"
        >
          <b-col cols="9">
            <b-media no-body class="align-items-center">
              <ProductThumbnail :product="product" />
              <b-media-body>
                <p v-if="product.vigieId" class="h6 text-muted mb-0">
                  Code produit: {{ product.vigieId }}
                </p>
                <p
                  v-if="product.isbn13 || product.isbn10"
                  class="h6 text-muted mb-0"
                >
                  ISBN: {{ product.isbn13 || product.isbn10 }}
                </p>
                <p v-if="product.name" class="h5 mb-0">
                  {{ product.name }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col align="end">
            <a
              href="#!"
              class="table-action table-action-delete"
              data-toggle="tooltip"
              data-original-title="Supprimer"
              @click="item.products.splice(index, 1)"
            >
              <i class="fas fa-trash" />
            </a>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <BaseButton type="default" size="sm" native-type="submit">
              <span class="btn-inner--text">{{
                item.new ? 'Ajouter le regroupement' : 'Enregistrer'
              }}</span>
            </BaseButton>

            <BaseButton type="secondary" size="sm" @click.prevent="cancel">
              <span class="btn-inner--text">Annuler</span>
            </BaseButton>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </BaseItemForm>
</template>
<script>
import { BaseInput, BaseRadio, BaseButton } from '@coop-zone/coop-zone-argon'
import BaseItemForm from '@/components/SchoolLists/Items/BaseItemForm'
import ProductAutocomplete from '@/components/ProductAutocomplete'
import ProductThumbnail from '@/components/Product/ProductThumbnail'

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseRadio,
    BaseItemForm,
    ProductAutocomplete,
    ProductThumbnail,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showProductSearch: false,
    }
  },
  computed: {
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
  },
  methods: {
    addProductToGroup(product) {
      this.item.products.push(product)
    },
  },
}
</script>
